import { markPasteRule, markInputRule } from '@tiptap/core';
import { Strike } from '@tiptap/extension-strike';

export const starInputRegex = /(?:^|\s)((?:~)((?:[^*]+))(?:~))$/;
export const starPasteRegex = /(?:^|\s)((?:~)((?:[^*]+))(?:~))/g;

export const StrikeCustom = Strike.extend({
  addInputRules() {
    return [
      markInputRule({
        find: starInputRegex,
        type: this.type,
      }),
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: starPasteRegex,
        type: this.type,
      }),
    ];
  },
});
