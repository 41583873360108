import { markPasteRule, markInputRule } from '@tiptap/core';
import { Italic } from '@tiptap/extension-italic';

export const starInputRegex = /(?:^|\s)((?:_)((?:[^*]+))(?:_))$/;
export const starPasteRegex = /(?:^|\s)((?:_)((?:[^*]+))(?:_))/g;

export const ItalicCustom = Italic.extend({
  addInputRules() {
    return [
      markInputRule({
        find: starInputRegex,
        type: this.type,
      }),
    ];
  },

  addPasteRules() {
    return [
      markPasteRule({
        find: starPasteRegex,
        type: this.type,
      }),
    ];
  },
});
