<template>
  <div class="grid justify-center items-center min-h-screen-content">
    <div
      class="bg-neutral-50 rounded-16 p-24 min-w-full w-[379px]"
    >
      <div
        class="text-3xl"
        data-test-id="landingWelcomeLabel"
      >
        {{ $t('login.welcome') }}
      </div>
      <div class="mt-8">{{ $t('login.welcome-description') }}</div>
      <Form
        :validation-schema="schema"
        class="my-56 flex flex-col gap-16"
        @submit="loginSubmit"
      >
        <Field v-slot="{ field, errors, setValue }" name="email">
          <HccInput
            type="email"
            required-input
            data-test-id="emailTextInput"
            :label="$t('login.email')"
            :model-value="field.value"
            placeholder="example@sellia.com"
            :error="errors.length > 0"
            :has-error="errors"
            icon-input-start="mdi:at"
            @keyup.enter="$refs.password.focus()"
            @update:model-value="setValue"
          />
        </Field>

        <Field v-slot="{ field, errors, setValue }" name="password">
          <HccInput
            ref="password"
            type="password"
            required-input
            data-test-id="passwordTextInput"
            :label="$t('login.password')"
            :model-value="field.value"
            placeholder="••••••••"
            :error="errors.length > 0"
            :has-error="errors"
            icon-input-start="ic:baseline-key"
            :actions="[{ type: 'showPassword', icon: 'mdi:eye' }]"
            @keyup.enter="$refs.password.focus()"
            @update:model-value="setValue"
          />
          <p
            v-show="errorMsg"
            class="text-red-700"
            data-test-id="invalidUserLabel"
            v-html="errorMsg"
          ></p>
        </Field>
        <div class="text-end">
          <router-link
            v-slot="{ navigate }"
            to="/forgot-password"
            custom
          >
            <hcc-button
              variant="link"
              size="xxs"
              data-test-id="forgotPassLink"
              @click="navigate"
            >
              {{ $t('login.forgot') }}
            </hcc-button>
          </router-link>
        </div>

        <hcc-button
          ref="clickBtn"
          type="submit"
          size="md"
          variant="fill"
          color="primary"
          :disabled="isLoading"
          data-test-id="loginButton"
        >
          {{ $t('login.log-in') }}
        </hcc-button>

        <div class="flex gap-16 justify-between items-center">
          <span class="h-2 bg-neutral-500 w-full rounded-2" />
          <span class="text-neutral-500">o</span>
          <span class="h-2 bg-neutral-500 w-full rounded-2" />
        </div>

        <hcc-button
          size="md"
          variant="fill"
          color="secondary"
          @click="loginGoogle"
        >
          <Icon icon="logos:google-icon" class="icon-md" />
          {{ $t('login.login-google') }}
        </hcc-button>
      </Form>

      <div class="flex gap-4">
        <p class="font-medium">{{ $t('login.no-account') }}</p>
        <router-link to="/create-account" class="button link secondary xss">
          {{ $t('login.register-here') }}
        </router-link>
      </div>

    </div>
  </div>
  <Toast ref="toast" />
</template>

<script setup>
import { onMounted, ref } from 'vue';
import { Field, Form } from 'vee-validate';
import { HccInput, HccButton } from '@sellia/base/components';
import { useUserStore } from '@/stores/user';
import router from '@/router';
import Toast from '@/components/Toast.vue';
import { Icon } from '@iconify/vue';
import { signin } from '@/services/auth';
import { useI18n } from 'vue-i18n';
import { datadogRum } from '@datadog/browser-rum';

const store = useUserStore();
const clientId = import.meta.env.VITE_GOOGLE_CLIENT_ID;
const redirectUri = import.meta.env.VITE_GOOGLE_REDIRECT_URI;
const googleAuthUri = import.meta.env.VITE_GOOGLE_AUTH_URI;
const { t } = useI18n();
const isLoading = ref(false);
const errorMsg = ref(null);
const clickBtn = ref();
const toast = ref(null);
const supportPortalUrl = import.meta.env.VITE_SUPPORT_PORTAL_URL;

const schema = {
  email: 'required|email',
  password: 'required|min:2',
};

const loginSubmit = async (values) => {
  isLoading.value = true;
  try {
    await store.login(values);

    if (store.requireFinishAccount) {
      store.setStepAccountURL();
      return;
    }

    if (store.forceChangePassword) {
      router.push({
        name: 'ChangePassword',
        query: {
          data: store.token,
        },
      });
      return;
    }
    window.location.href = store.getBaseUrl();
  } catch (e) {
    if (e.response && e.response.status === 401) {
      const { message, name } = e.response.data;

      if (name === 'companyCanceled') {
        errorMsg.value = `${message}
        <a style="color: #d0242b !important;" href="${supportPortalUrl}">${
  t('common.here' )
}</a>`;
        return;
      }

      errorMsg.value = message;
    } else {
      datadogRum.addError(e);
    }
  } finally {
    isLoading.value = false;
  }
};

const loginGoogle = () => {
  window.location.href = `${googleAuthUri}?client_id=${clientId}&redirect_uri=${
    redirectUri
  }&response_type=code&scope=profile email`;
};

const exchangeGoogleToken = async (code) => {
  try {
    const { data } = await signin({ code });

    store.setData(data);

    if (data.redirect) {
      if (data.redirect === 'payment') {
        store.setStepCreatingAccount(4);
      } else if (data.redirect === 'company') {
        store.setStepCreatingAccount(3);
      }

      store.setStepAccountURL();
      return;
    }

    if (!data.token) {
      router.push({
        name: 'linkGoogleAccount',
        query: {
          email: data.email,
        },
      });

      return;
    }

    store.setData(data);
    window.location.href = store.getBaseUrl();
  } catch (error) {
    if (error.response?.data?.message) {
      errorMsg.value = error.response.data.message;
      return;
    }

    errorMsg.value = t('default-error');
    datadogRum.addError(error);
  }
};

onMounted(() => {
  if (store.requireFinishAccount) {
    store.setStepAccountURL();
    return;
  }

  if (store.isValidToken()) {
    window.location.href = store.getBaseUrl();
  }

  const { error, code } = router.currentRoute.value.query;

  if (code) {
    exchangeGoogleToken(code);
  }

  if (error) {
    errorMsg.value = error;
  }
});
</script>
